<template>
  <img
    src="~assets/image/tab_banner/thumbnail/incubation_thumbnail.jpg"
    alt="孵化引进"
    class="page-banner"
    style="width: 100%"
    v-lazyLoadImage="require('assets/image/tab_banner/incubation.png')"
  />
  <div class="incubate-box">
    <point-title title="孵化企业" />
    <ul class="incubate-contact">
      <li
        v-for="(item, index) in companyList"
        :key="item.name"
        @click="seeMore(index, item)"
      >
        <div class="title">
          <h3>{{ item.name }}</h3>
          <div class="more-button">MORE</div>
        </div>
        <div class="contact">
          <div class="img-box">
            <img
              :src="require(`assets/image/company_logo/${item.img_name}`)"
              :alt="item.name"
              class="logo"
            />
          </div>
          <div class="detail" v-html="item.detail" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// import { useRouter } from "vue-router";
import companyList from "./companyList.js";
export default {
  name: "Incubate",
  setup() {
    const seeMore = (id, item) => {
      if (item.name == "南京瑞牧星科技有限公司") {
        window.open(`https://www.remosing.com/`, "_blank");
        return;
      }
      if (item.name == "南京拓服工坊科技有限公司") {
        window.open(
          `https://www.tofwerk.com/zh-hans/guanyutofwerkzhongguo/`,
          "_blank"
        );
        return;
      }
      window.open(`/#/CompanyDetail?id=${id}`, "_blank");
    };
    return { companyList, seeMore };
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/page/incubate.scss";
</style>
