export default [
  {
    name: "南京和云科技有限公司",
    img_name: "和云科技.png",
    detail: `南京和云科技有限公司主要从事以数据集成分析为手
          段的大气超级站数据综合应用、项目开发及技术、咨询服务。从多站点网格化的面上多类型大数据整合，基于大气组分监测数据的污染成因分析，到大气超站数据集成平台的开发，和云科技深耕以数据为本的大气污染综
          合解析业务，以基于监测数据对大气污染关键过程、关键控制因素的深刻理解为客户提供的快速解决方案。`,
  },
  {
    name: "南京叁云科技有限公司",
    img_name: "叁云科技.png",
    detail: `南京叁云云科技有限公司主要从事气象多领域服务，采用最先进的人工智能机器学习技术与传统气象数值模式预报
          相结合的方法，提供客观化、网格化、精细化的气象预报产品，为用户提供定制化的气象数据处理研发和气象预报技术
          研发服务。并通过大数据挖掘结合细分行业领域内在需求，
          通过气象与行业大数据融合为各类用户提供基于行业影响的
          的专业气象咨询服务产品。`,
  },
  {
    name: "江苏宁清信息科技有限公司",
    img_name: "宁清信息.png",
    detail: `宁清信息科技以大气移动监测和工业园区监测需求发展为导向, 通过引进国内外知名产品以及国内专家，构建大气环境领域专业经营团队,实现业务设计创新与服务能力升级，从而不断加强公司在国内的企业价值。公司核心围绕环保部门痛点需求，构建化工园区监测和 服务咨询业务协同发展的业务体系，创造顶级口碑的个 性化服务成为公司发展的企业标签。`,
  },
  {
    name: "南京山水云天生态技术有限公司",
    img_name: "山水云天生态.png",
    detail: `南京山水云天生态技术有限公司成立于2020年01月16日，注册地位于南京市浦口区江浦街道浦滨路320号A座22楼2204室，法定代表人为熊巍旭。经营范围包括生态科技、信息技术、环境技术、软件研发、技术咨询、技术服务、技术转让；仪表仪器研发、生产、安装、销售；软件销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）`,
  },
  {
    name: "南京瑞牧星科技有限公司",
    img_name: "瑞牧星科技.png",
    detail: `南京瑞牧星科技有限公司基于各种静止和极轨卫星数据，并利用数值模型、机器学习方法等提供定量反演产品和应用服务，协助高校研究人员从事卫星相关的学术活动，支撑气象和环境相关的企事业单位的日常业务，是研究院在卫星数据应用方面的重点孵化企业。以国家双一流建设高校为依托，公司组建了一支在深度学习和卫星遥感有关的工作方面的有实力研发团队，在卫星数据的获取、分析、反演...`,
  },
  {
    name: "南京朗世科技有限责任公司",
    img_name: "朗世科技.png",
    detail: `南京朗世科技有限责任公司是大气臭氧污染有、着从监测到分析方面的丰富经验，是大气环境研究院在臭氧污染源解析方向的重要孵化对象。<br />朗世科技优势：1.ppt级大气挥发性有机化合物分析技术2.包括PMF、CMB、PCA-APCS等在内的挥发性有机 化合物源解析技术3.基于MCM、CB05等化学反应机制构建的大气光化学反应模式，对臭氧生成过程、臭氧生成敏 感性、臭氧来源进行...`,
  },
  {
    name: "南京拓服工坊科技有限公司",
    img_name: "拓服工坊.png",
    detail: `南京拓服工坊科技有限公司（TOFWERK）是一家专注于研发基于飞行时间质谱（time of flight）平台的分析仪器公司，为实验室检测和场外监测提供多种类的仪器解决方案，是研究院高端大气方向的重点引进公司。本公司产品线包含有电感耦合等离子体-飞行时间质谱(icpTOF)，Vocus质子转移反应-飞行时 间质谱（Vocus PTR-TOF），离子淌度-飞行时间质谱（IMS-TOF）和可搭配气相色谱的电子电...`,
  },
];
